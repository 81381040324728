<template>
  <div class="flex flex-col m-5 sm:w-[350px] sm:mr-auto sm:ml-auto">
    <img src="../assets/logo.png" class="object-cover pl-20 pr-20 md:mb-10"/>
    <h1 class="font-bold text-4xl mt-10 text-center">Session Timer</h1>
    <h2 class="text-center text-slate-500">Das Tool um eine Reihe von Countdowns mit Anweisungen für deinen Workshop zu erstellen.</h2>
    <label v-if="isFormCorrect" class="block text-xs text-slate-400 mt-10">Name der Session</label>
    <label v-if="!isFormCorrect" class="block text-xs text-red-400 mt-10">Name der Session</label>
    <input v-model="inputTitle" type="text" class="config-content">
    <button @click="createSession" class="btn-control w-44 font-bold">
        <ion-icon name="alarm-outline" class="w-6 h-6"></ion-icon> Session erstellen
    </button>
  </div>
</template>
<script>
import axios from "axios";
import router from '@/router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    setup () {
        return { v$: useVuelidate() }
    },
    data() { 
        return {
            inputTitle: '',
            isFormCorrect: true,
        }
    },
    methods: {
        async createSession() {
            this.isFormCorrect = await this.v$.$validate();
            if (this.isFormCorrect) {
                try {
                    const response = await axios.post("/.netlify/functions/create-session", { title: this.inputTitle });
                    this.sessionId = response.data._id;
                    console.log("Neue Session erstellt mit ID:", this.sessionId);
                    router.push('/' + this.sessionId);
                    // Du kannst jetzt mit der neuen Session-ID weiterarbeiten
                } catch (error) {
                    console.error("Fehler beim Erstellen der Session:", error);
                }
            }
        }
    },
    validations () {
        return {
            inputTitle: { required }
        }
    }
}
</script>