import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeView.vue';
import SessionTimer from '../components/SessionTimer.vue';

// Routen definieren
const routes = [
    {
        path: '/', 
        component: HomeView,
    },
    {
        path: '/:id',
        component: SessionTimer,
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

export default router;