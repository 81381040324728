<template>

  <div v-if="saving" class="absolute left-1 top-1 z-50">Speichern läuft!!!</div>

  <div class="md:flex md:h-max text-gray-600"> <!-- Wrapper Container Timer -->
  
  <div class="flex-none p-4 md:w-96 md:border-r md:h-screen mr-auto ml-auto"> <!-- Toolbar -->
    <div class="mb-12">
      <img src="../assets/logo.png" class="object-scale-down mr-auto ml-auto"/>
    </div>

    <div> <!-- Config Area -->
      <div class="mt-7">
        <h3 class="config-header text-center trenda-bold">Countdowns</h3>
          <div v-for="(countdown, index) in countdowns" :key="index">
            <div class="config-content flex items-center" v-bind:class="{ active : countdowns[index].active }">
              <div
                class="w-12 flex-0">{{ formatTime(countdown.minutes, countdown.seconds) }}</div>
              <div
                class="truncate pl-1 flex-auto"> {{ countdown.title }}</div>
              <div class="w-20 felx-0 flex justify-end">
                <ion-icon 
                  name="chevron-up-outline"
                  @click="moveCountdownUp(index)" 
                  data-te-toggle="tooltip"
                  title="Countdown ein nach oben verschieben"
                  class="btn-countdown"></ion-icon>
                <ion-icon 
                  name="chevron-down-outline"
                  @click="moveCountdownDown(index)" 
                  data-te-toggle="tooltip"
                  title="Countdown ein nach unten verschieben"
                  class="btn-countdown"></ion-icon>
                <ion-icon 
                  name="create-outline" 
                  @click="setEditCountdown(index)"
                  data-te-toggle="tooltip"
                  title="Countdown bearbeiten"
                  class="btn-countdown"></ion-icon>
                <ion-icon 
                  name="trash-outline"
                  @click="deleteCountdown(index)" 
                  data-te-toggle="tooltip"
                  title="Countdown löschen"
                  class="btn-countdown"></ion-icon>
              </div>
                
            </div>
          </div>
      </div>
      <button @click="addCountdown" 
        data-te-toggle="tooltip"
        title="Neuen Countdown hinzufügen"
        class="btn-control"><ion-icon 
        name="add-outline"
        ></ion-icon>
      </button>

      <!-- Modal Edit Area -->
      <div v-if="isEditOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="isEditOpen = false"></div>

      <div v-if="isEditOpen"  
        class="fixed p-5 m-5 sm:w-96 bg-white rounded-lg inset-0 items-center z-50">
        <div class="flex">
          <div class="grow text-slate-500">
            Countdown bearbeiten
            <div class="text-slate-300 text-xs">Änderungen werden automatisch gespeichert</div>
          </div>
          
          <ion-icon 
            class="relative grow-0 top-0 right-0 p-2 border rounded cursor-pointer" 
            name="close-outline" 
            @click="isEditOpen = false"
            data-te-toggle="tooltip"
            title="Bearbeitungsfenster schließen"></ion-icon>
        </div>
        <h3 class="config-header">Titel</h3>
        <input 
            name="inputTitle"
            class="config-content w-full" 
            v-model="inputTitle"
            @input="updateCountdown" 
            type="text" 
            placeholder="">
            <h3 class="config-header">Dauer</h3>
        <div class=" config-content flex items-end">
          <div class="grow text-right">
            <label
              class="block text-xs text-slate-400" 
              for="inputMinutes">Minuten</label>
            <input
              name="inputMinutes" 
              v-model.number="inputMinutes"
              @input="updateCountdown"
              type="number" min="0"
              placeholder="Minutes"
              size="3"/>
          </div>
          <span class="grow-0 ml-3 mr-3">:</span>
          <div class="grow text-right">
            <label
              class="block text-xs text-slate-400" 
              for="inputMinutes">Sekunden</label>
            <input 
              name="inputSeconds"
              class="grow"
              v-model.number="inputSeconds"
              @input="updateCountdown" 
              type="number" min="1" max="59" 
              placeholder="Seconds"
              size="2"/>
          </div>
        </div>
        <h3 class="config-header">Anleitung</h3>
        <div>
          <QuillEditor 
            ref="quill"
            v-model:content="inputInstructions"
            content-type="html"
            @update:content="updateCountdown"
            @textChange="checkLength"
            :options="instructionsEditorOptions"
            class="rounded"
            theme="snow"></QuillEditor>
            <div id="char-count" class="text-right text-xs text-slate-500">{{ currentChars }} / {{ maxChars }}</div>
        </div>
        </div> <!-- Modal Edit Area -->
        
      </div> <!-- End Config Area -->
  </div> <!-- End Toolbar -->
  <hr class="md:hidden">
  <main id="main" class="md:flex-2 flex md:flex-grow flex-col md:w-10 p-4 static md:h-screen "> <!-- Main Timer Area -->
    
    <div v-if="!currentCountdown" class="flex-grow flex flex-col h-screen">
      <h1 class="flex-grow grid place-items-center text-9xl font-bold text-center p-28">Yeah! Die Session ist vorbei.</h1>
      <!--ion-icon class="flex-grow h-10 w-10 btn-control" name="refresh-outline" @click="restartTimer"></ion-icon-->
    </div>

    <div v-if="currentCountdown" class="flex-grow flex flex-col">
      <div class="h-14 text-right flex">
        <div class="flex-1 p-2 text-left">Session Titel: <strong>{{ session.title }}</strong></div>
        <div class="flex-1">
          <div v-if="nextCountdown" class="text-sm bg-slate-100 rounded-lg inline-block p-2">
            <span>Countdown</span>&nbsp;
            <span>{{ currentCountdownIndex + 1 }} von {{ countdowns.length }}</span>
          </div>
        </div>
      </div>
      <div class="flex-grow flex flex-col">
        
        <h1 class="flex-grow grid place-items-center text-center text-7xl mb-8 md:mb-1">
          {{ currentCountdown.title }}
        </h1>
      
        <h1 class="flex-grow grid place-items-top text-center text-7xl mb-8 md:mb-1 text-slate-300">
          {{ currentTime }}
        </h1>

        <div class="border rounded-lg shadow p-5 flex-grow">
          <h2 class="font-bold">Anleitung</h2>
          <div id="instructions" class="text-2xl" v-html="currentCountdown.instructions"></div>
        </div>
      </div>
    </div>
    
    <div id="controls" class="flex text-right h-14 items-end justify-end">
        <button 
          class="btn-control mr-2"
          @click="addMinutes(1)"
          data-te-toggle="tooltip"
          title="Eine Minute dem aktuellen Countdown hinzufügen">
          <ion-icon name="add"></ion-icon> 1</button>
        <button class="btn-control mr-2"
          @click="addMinutes(5)"
          data-te-toggle="tooltip"
          title="5 Minuten dem aktuellen Countdown hinzufügen">
          <ion-icon name="add"></ion-icon> 5</button>
        <button class="btn-control mr-2" v-if="!playing"><ion-icon
          name="play"
          @click="startTimers"
          data-te-toggle="tooltip"
          title="Session starten / fortsetzen"></ion-icon></button>
        <button class="btn-control mr-2"  v-if="playing"><ion-icon 
          name="pause"
          @click="stopTimers"
          data-te-toggle="tooltip"
          title="Session pausieren"></ion-icon></button>
        <button class="btn-control" v-if="!isFullscreen"><ion-icon
          name="expand-outline"
          @click="goFullscreen"
          data-te-toggle="tooltip"
          title="Maximieren auf ganzen Bildschirm"></ion-icon></button>
        <button class="btn-control" v-if="isFullscreen"><ion-icon 
          name="contract-outline"
          @click="leaveFullscreen"
          data-te-toggle="tooltip"
          title="Minimieren von ganzem Bildschirm"></ion-icon></button>
      </div>
  </main> <!-- End Main Timer Area -->

  
</div> <!-- End Wrapper Container -->

</template>

<script setup>
import { ref } from 'vue';

const quill = ref();
const maxChars = 800;
let currentChars = 0;

function checkLength(delta) {
  currentChars = quill.value.getQuill().getLength();
  if (currentChars <= maxChars) {
    return;
  }
  const { ops } = delta.delta;
  let updatedOps;
  if (ops.length === 1) {
    // text is inserted at the beginning
    updatedOps = [{ delete: ops[0].insert.length }];
  } else {
    // ops[0] == {retain: numberOfCharsBeforeInsertedText}
    // ops[1] == {insert: "example"}
    updatedOps = [ops[0], { delete: ops[1].insert.length }];
  }
  quill.value.getQuill().updateContents({ ops: updatedOps });
}

</script>

<script>
import axios from "axios";
import { onMounted } from "vue";
import { Tooltip, initTE } from "tw-elements";
import NoSleep from "nosleep.js";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor
  },
  async created() {
    this.sessionId = this.$route.params.id;
    this.loadSession();
    this.noSleep = new NoSleep();

    onMounted(() => {
      if (window.innerWidth > 640)
        initTE({ Tooltip });
    });
  },
  data() {
    return {
      sessionId: null,
      countdowns: [
        { title: 'Frage 1', instructions: '<strong>Hallo Welt!</strong>', minutes: 0, seconds: 1},
        { title: 'Frage 2', instructions: '<strong>Hallo Welt!</strong>', minutes: 0, seconds: 1}
      ],
      originalCountdown: {},
      currentCountdown: {},
      currentCountdownIndex: 0,
      editCountdown: {},
      nextCountdown: null,
      inputTitle: '',
      inputInstructions: '',
      inputMinutes: 0,
      inputSeconds: 0,
      interval: null,
      alarmSound: null,
      currentTime: '',
      isEditOpen: false,
      isFullscreen: false,
      playing: false,
      session: {},
      saving: false,
      instructionsEditorOptions: {
        debug: 'info',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
          ],
        },
        readOnly: false,
        theme: 'snow'
      },
    };
  },
  mounted() {
    this.alarmSound = new Audio('bell.mp3');
    this.currentCountdown = this.countdowns[0];
    this.editCountdown = this.countdowns[0];
    this.updateInputs();
    this.updateCurrentTime();
    this.setNextCountdown();
  },
  methods: {
    formatTime(minutes, seconds) {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    updateCurrentTime() {
      if (this.currentCountdown != null)
        this.currentTime = this.formatTime(this.currentCountdown.minutes, this.currentCountdown.seconds);
    },
    startTimers() {
      this.playing = true;
      this.setCurrentCountdown();
      if (!this.interval) {
        this.interval = setInterval(() => {
          if (this.currentCountdown.seconds === 0) {
            if (this.currentCountdown.minutes === 0) {
              this.moveToNextCountdown();
            } else {
              this.currentCountdown.minutes--;
              this.currentCountdown.seconds = 59;
            }
          } else {
            this.currentCountdown.seconds--;
          }
          this.updateCurrentTime();
        }, 1000);
      }
    },
    stopTimers() {
      this.playing = false;
      this.noSleep.disable();
      clearInterval(this.interval);
      this.interval = null;
    },
    resetTimers() {
      this.noSleep.enable();
      this.stopTimers();
      this.countdowns = [];
      this.currentCountdown = { 
        title: this.defaultCountdown.title, 
        instructions: this.defaultCountdown.instructions, 
        minutes: this.defaultCountdown.minutes, 
        seconds: this.defaultCountdown.seconds };
    },
    updateInputs() {
      this.inputTitle = this.editCountdown.title;
      this.inputInstructions = this.editCountdown.instructions;
      this.inputMinutes = this.editCountdown.minutes;
      this.inputSeconds = this.editCountdown.seconds;
    },
    updateCountdown() {
      this.editCountdown.title = this.inputTitle;
      this.editCountdown.instructions = this.inputInstructions;
      this.editCountdown.minutes = this.inputMinutes;
      this.editCountdown.seconds = this.inputSeconds;
      this.updateCurrentTime();
      this.saveSession();
    },
    deleteCountdown(index) {
      this.countdowns.splice(index, 2);
      this.setNextCountdown();
    },
    setEditCountdown(index) {
      this.editCountdown = this.countdowns[index];
      this.updateInputs();
      this.isEditOpen = true;
    },
    addCountdown() {
      this.countdowns.push({ 
        title: 'Countdown ' + (this.countdowns.length + 1), 
        instructions: '', 
        minutes: 1, 
        seconds: 0 });
      this.setNextCountdown();
      this.saveSession();
    },
    moveToNextCountdown() {
      this.currentCountdownIndex++;
      this.setCurrentCountdown();
      if (this.currentCountdown != null) {
        this.alarmSound.play();
        this.setNextCountdown();
      } else {
        this.stopTimers();
      }
    },
    setCurrentCountdown() {
      if (this.countdowns[this.currentCountdownIndex] != undefined) {
        if(this.currentCountdown === null) this.currentCountdown = {};
        this.currentCountdown.active = false;
        this.currentCountdown = this.countdowns[this.currentCountdownIndex];
        this.currentCountdown.active = true;
      } else {
        this.currentCountdown = null;
      }
      this.updateCurrentTime();
      this.setNextCountdown();
    },
    setNextCountdown() {
      if (this.countdowns[this.currentCountdownIndex + 1] != undefined)
        this.nextCountdown = this.countdowns[this.currentCountdownIndex + 1];
      else this.nextCountdown = null;
    },
    goFullscreen() {
      this.isFullscreen = true;
      let elem = document.getElementById("main");

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    },
    leaveFullscreen() {
      this.isFullscreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
    restartTimer() {
      this.currentCountdownIndex = 0;
      this.setCurrentCountdown();
      this.startTimers();
    },
    swapCountdowns(indexA, indexB) {
      const temp = this.countdowns[indexA];
      this.countdowns[indexA] = this.countdowns[indexB];
      this.countdowns[indexB] = temp;
      this.saveSession();
    },
    moveCountdownUp(index) {
      if (index > 0)
        this.swapCountdowns(index, index - 1);
    },
    moveCountdownDown(index) {
      if (index < this.countdowns.length - 1)
        this.swapCountdowns(index, index + 1);
    },
    async loadSession() {
      try {
        console.log('Component SessionTimer.loadSession: ' + this.sessionId);
        const response = await axios.get('/.netlify/functions/get-session/', {params: { id: this.sessionId }});
        console.log(response.data);
        this.setSession(response.data);
      } catch (error) {
        console.error("Fehler beim Laden der Session:", error);
      }
      
    },
    async saveSession() {
      this.saving = true;
      
      axios({
        method: 'put',
        url: '/.netlify/functions/update-session/',
        data: this.session
      }).catch(error => {
        console.error('Fehler beim Update der Session', error)
      });
      this.setCurrentCountdown();
      this.saving = false;
    },
    setCountdowns(countdowns) {
      this.countdowns = countdowns;
      this.currentCountdownIndex = 0;
      this.setCurrentCountdown();
    },
    addMinutes(minutes) {
      console.log('Adding '+ minutes + ' minutes to current countdown' );
      this.setCurrentCountdown();
      this.currentCountdown.minutes += minutes;
      this.saveSession();
      this.updateInputs();
      this.updateCurrentTime();
    },
    setSession(session) {
      this.session = session;
      this.countdowns = this.session.countdowns;
      this.currentCountdownIndex = 0;
      this.setCurrentCountdown();
    },
  },
};


</script>