<template>

  <router-view></router-view>

</template>

<script>
export default {
  components: {},
};
</script>
<style></style>
