import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// create Vue App and add modules
const app = createApp(App);
app.use(router);
app.mount('#app');

// eigene Styles importieren
import './assets/styles.css'

// Importiere Tailwind CSS
import './assets/tailwind.css'

// import QuillEditor
/*import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
app.component('QuillEditor', QuillEditor);*/